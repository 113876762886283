/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BerechnungsKnotenTyp } from './BerechnungsKnotenTyp';
import {
    BerechnungsKnotenTypFromJSON,
    BerechnungsKnotenTypFromJSONTyped,
    BerechnungsKnotenTypToJSON,
} from './BerechnungsKnotenTyp';

/**
 * Ein Knoten mit Berechnungsparameter (Anzahl, Laufmeter, ...).
 * @export
 * @interface ParameterKnoten
 */
export interface ParameterKnoten {
    /**
     * 
     * @type {number}
     * @memberof ParameterKnoten
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterKnoten
     */
    parameterId: number;
    /**
     * 
     * @type {BerechnungsKnotenTyp}
     * @memberof ParameterKnoten
     */
    knotenTyp: BerechnungsKnotenTyp;
    /**
     * 
     * @type {number}
     * @memberof ParameterKnoten
     */
    uebergeordnetId?: number;
}

/**
 * Check if a given object implements the ParameterKnoten interface.
 */
export function instanceOfParameterKnoten(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "parameterId" in value;
    isInstance = isInstance && "knotenTyp" in value;

    return isInstance;
}

export function ParameterKnotenFromJSON(json: any): ParameterKnoten {
    return ParameterKnotenFromJSONTyped(json, false);
}

export function ParameterKnotenFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterKnoten {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parameterId': json['parameterId'],
        'knotenTyp': BerechnungsKnotenTypFromJSON(json['knotenTyp']),
        'uebergeordnetId': !exists(json, 'uebergeordnetId') ? undefined : json['uebergeordnetId'],
    };
}

export function ParameterKnotenToJSON(value?: ParameterKnoten | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parameterId': value.parameterId,
        'knotenTyp': BerechnungsKnotenTypToJSON(value.knotenTyp),
        'uebergeordnetId': value.uebergeordnetId,
    };
}

