/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParameterTagKante } from './ParameterTagKante';
import {
    ParameterTagKanteFromJSON,
    ParameterTagKanteFromJSONTyped,
    ParameterTagKanteToJSON,
} from './ParameterTagKante';

/**
 * 
 * @export
 * @interface TagKantenAktualisierenRequest
 */
export interface TagKantenAktualisierenRequest {
    /**
     * 
     * @type {Array<ParameterTagKante>}
     * @memberof TagKantenAktualisierenRequest
     */
    loeschen: Array<ParameterTagKante>;
    /**
     * 
     * @type {Array<ParameterTagKante>}
     * @memberof TagKantenAktualisierenRequest
     */
    hinzufuegen: Array<ParameterTagKante>;
}

/**
 * Check if a given object implements the TagKantenAktualisierenRequest interface.
 */
export function instanceOfTagKantenAktualisierenRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "loeschen" in value;
    isInstance = isInstance && "hinzufuegen" in value;

    return isInstance;
}

export function TagKantenAktualisierenRequestFromJSON(json: any): TagKantenAktualisierenRequest {
    return TagKantenAktualisierenRequestFromJSONTyped(json, false);
}

export function TagKantenAktualisierenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagKantenAktualisierenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'loeschen': ((json['loeschen'] as Array<any>).map(ParameterTagKanteFromJSON)),
        'hinzufuegen': ((json['hinzufuegen'] as Array<any>).map(ParameterTagKanteFromJSON)),
    };
}

export function TagKantenAktualisierenRequestToJSON(value?: TagKantenAktualisierenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'loeschen': ((value.loeschen as Array<any>).map(ParameterTagKanteToJSON)),
        'hinzufuegen': ((value.hinzufuegen as Array<any>).map(ParameterTagKanteToJSON)),
    };
}

