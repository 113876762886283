/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParameterTag
 */
export interface ParameterTag {
    /**
     * 
     * @type {number}
     * @memberof ParameterTag
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ParameterTag
     */
    name: string;
    /**
     * Tags vom gleichen Typ schließen sich innerhalb eines Berechnungsparameters aus. Zum Beispiel haben Gleis und Weiche den selben Typ.
     * @type {number}
     * @memberof ParameterTag
     */
    typId: number;
    /**
     * Enthält IDs von benachbarten Tags die zusammen einen definierten Berechnungsparameter ergeben (z.B. Stopfen-Gleis-Neulage-Meter).
     * @type {Array<number>}
     * @memberof ParameterTag
     */
    nachbarIds: Array<number>;
    /**
     * Ein optionaler Tag wird ebenfalls zum definieren von Berechnungsparametern verwendet, ist jedoch nicht erforderlich. Zum Beispiel ist Stopfen-Gleis-Neulage-Meter ausreichend definiert, könnte jedoch zusätzlich Teilstopfen enthalten und somit einen neuen Parameter abbilden.
     * 
     * @type {boolean}
     * @memberof ParameterTag
     */
    optional: boolean;
}

/**
 * Check if a given object implements the ParameterTag interface.
 */
export function instanceOfParameterTag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "typId" in value;
    isInstance = isInstance && "nachbarIds" in value;
    isInstance = isInstance && "optional" in value;

    return isInstance;
}

export function ParameterTagFromJSON(json: any): ParameterTag {
    return ParameterTagFromJSONTyped(json, false);
}

export function ParameterTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'typId': json['typId'],
        'nachbarIds': json['nachbarIds'],
        'optional': json['optional'],
    };
}

export function ParameterTagToJSON(value?: ParameterTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'typId': value.typId,
        'nachbarIds': value.nachbarIds,
        'optional': value.optional,
    };
}

