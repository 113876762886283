import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import FailedView from "../views/FailedView.vue";
import { RouteNames } from "./RouteNames";
import { registerGuard } from "./Guard";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HOME",
    component: HomeView,
  },

  {
    path: "/failed",
    name: "Failed",
    component: FailedView,
  },

  {
    path: "/Umsaetze",
    name: RouteNames.UMSAETZE,
    component: () => import("../views/UmsaetzeView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Umsätze",
    },
  },
  {
    path: "/Umsaetze/:sid",
    name: RouteNames.UMSATZ,
    component: () => import("../views/UmsatzView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Umsatz",
    },
  },

  {
    path: "/Strategie",
    name: RouteNames.STRATEGIE,
    component: () => import("../views/StrategieView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Strategie",
    },
  },

  {
    path: "/Umsatzprognose",
    name: RouteNames.SALESFORECAST,
    component: () => import("../views/SalesForecastView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Umsatzprognose",
    },
  },

  {
    path: "/Signalwoerter",
    name: RouteNames.SIGNALWORDS,
    component: () => import("../views/SignalWordConfigView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Signalwörter",
    },
  },

  {
    // Small workaround for MSAL: The redirect back looks like "#/code=0.AR8..."
    // VueRouter does not like that so to prevent useless console errors, we just make it a valid route to home.
    path: "/code=:afterCode(.*)",
    name: "OAuthCodeReturn",
    component: HomeView,
  },

  {
    path: "/system/tags",
    name: RouteNames.TAG_MANAGEMENT,
    component: () => import("../views/system/TagManagementView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Tag Management",
    },
  },
  {
    path: "/system/forecastParameters",
    name: RouteNames.FORECAST_PARAMETER_MANAGEMENT,
    component: () => import("../views/system/ForecastParametersView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Berechnungsparameter",
    },
  },
  {
    path: "/system/calculationTree",
    name: RouteNames.CALCULATION_TREE,
    component: () => import("../views/system/CalculationTreeView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Berechnungsbaum",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

registerGuard(router);

export default router;
