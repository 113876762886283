/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MaschinenGruppe
 */
export interface MaschinenGruppe {
    /**
     * 
     * @type {string}
     * @memberof MaschinenGruppe
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof MaschinenGruppe
     */
    firmaId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof MaschinenGruppe
     */
    tagIds: Array<number>;
}

/**
 * Check if a given object implements the MaschinenGruppe interface.
 */
export function instanceOfMaschinenGruppe(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "firmaId" in value;
    isInstance = isInstance && "tagIds" in value;

    return isInstance;
}

export function MaschinenGruppeFromJSON(json: any): MaschinenGruppe {
    return MaschinenGruppeFromJSONTyped(json, false);
}

export function MaschinenGruppeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MaschinenGruppe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'firmaId': json['firmaId'],
        'tagIds': json['tagIds'],
    };
}

export function MaschinenGruppeToJSON(value?: MaschinenGruppe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'firmaId': value.firmaId,
        'tagIds': value.tagIds,
    };
}

