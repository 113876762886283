/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ParameterTagKante
 */
export interface ParameterTagKante {
    /**
     * 
     * @type {number}
     * @memberof ParameterTagKante
     */
    vonId: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterTagKante
     */
    bisId: number;
}

/**
 * Check if a given object implements the ParameterTagKante interface.
 */
export function instanceOfParameterTagKante(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vonId" in value;
    isInstance = isInstance && "bisId" in value;

    return isInstance;
}

export function ParameterTagKanteFromJSON(json: any): ParameterTagKante {
    return ParameterTagKanteFromJSONTyped(json, false);
}

export function ParameterTagKanteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterTagKante {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vonId': json['vonId'],
        'bisId': json['bisId'],
    };
}

export function ParameterTagKanteToJSON(value?: ParameterTagKante | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vonId': value.vonId,
        'bisId': value.bisId,
    };
}

