/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UmsatzQuelle = {
    Automatisch: 'AUTOMATISCH',
    ManuellPrognose: 'MANUELL_PROGNOSE',
    Bericht: 'BERICHT',
    ManuellBericht: 'MANUELL_BERICHT'
} as const;
export type UmsatzQuelle = typeof UmsatzQuelle[keyof typeof UmsatzQuelle];


export function UmsatzQuelleFromJSON(json: any): UmsatzQuelle {
    return UmsatzQuelleFromJSONTyped(json, false);
}

export function UmsatzQuelleFromJSONTyped(json: any, ignoreDiscriminator: boolean): UmsatzQuelle {
    return json as UmsatzQuelle;
}

export function UmsatzQuelleToJSON(value?: UmsatzQuelle | null): any {
    return value as any;
}

