import { Ref, watch } from "vue";

export interface BaseApis {
  /**
   * States if the app got an access token yet.
   * Initially, this is false and if an access token is received once, it is set to true.
   * The state is only set once, so it will then forever be true.
   *
   * <p>It is intended to be used for the initial waiting period to initialize a user interface.
   * When the page first loads, one can check if making the first requests even make sense and
   * register a watcher on it as soon it is possible.
   * Since it stays true, it will never trigger a watcher later, even if the token becomes invalid at some point and
   * therefore will not unexpectedly e.g. trigger a reload of the UI.
   * This might simplify registering on multiple watch sources without special case handling of the access token.
   */
  isTokenInitialized: Ref<boolean>;
  /**
   * States if the access token is currently valid.
   * This is intended to be used e.g. for buttons that trigger actions via the backend api.
   * Before making a request this variable can be used to block a request that would fail because of invalid authentication.
   *
   * <p>The variable is false before having any access token at all and also if the token expired.
   * The variable will be set to true again as soon as a new access token can be acquired.
   */
  isTokenCurrentlyValid: Ref<boolean>;

  callWhenTokenInitialized: (toCall: Function | Function[]) => void;
}

export function setupCallWhenTokenInitialized(
  isTokenInitialized: Ref<boolean>,
) {
  return (toCall: Function | Function[]) => {
    let calls = Array.isArray(toCall) ? toCall : [toCall];
    if (isTokenInitialized.value) {
      calls.forEach((f) => f());
    } else {
      const unwatch = watch(
        isTokenInitialized,
        (v) => {
          if (v) {
            unwatch();
            calls.forEach((f) => f());
          }
        },
        { immediate: true },
      );
    }
  };
}
