<template>
  <div class="home">
    <span v-if="!isAuthenticated"
      >Bitte anmelden, um die Applikation zu verwenden.</span
    >
    <template v-else>
      <div class="card-container">
        <Card class="menu-card">
          <template #title>
            <div class="card-title">Ansicht wählen</div>
          </template>
          <template #content>
            <Button
              @click="router.push({ name: RouteNames.UMSAETZE })"
              :disabled="!roles?.canReadSales"
            >
              Umsätze
            </Button>
            <Button
              @click="router.push({ name: RouteNames.STRATEGIE })"
              :disabled="!roles?.canWriteStrategy"
            >
              Strategie
            </Button>
            <Button @click="router.push({ name: RouteNames.SALESFORECAST })">
              Umsatzprognose
            </Button>
            <Button
              :disabled="!roles?.canWriteSignalWord"
              @click="router.push({ name: RouteNames.SIGNALWORDS })"
            >
              Signalwörter
            </Button>
          </template>
        </Card>

        <Card v-if="isAdmin" class="menu-card">
          <template #content>
            <Card>
              <template #content>
                <FloatLabel style="margin-top: 0.9rem">
                  <InputText
                    id="railiumIds"
                    v-model="idsRaw"
                    fluid
                    style="min-width: 15rem"
                  />
                  <label for="railiumIds">Komma getrennte Railium IDs</label>
                </FloatLabel>
                <Button
                  :loading="isImporting"
                  @click="importRailium()"
                  label="Synchronisieren"
                />
              </template>
            </Card>
          </template>
        </Card>

        <Card>
          <template #title>
            <div class="card-title">System</div>
          </template>
          <template #content>
            <Button
              label="Tags"
              @click="router.push({ name: RouteNames.TAG_MANAGEMENT })"
            />
            <Button
              label="Berechnungsparameter"
              @click="
                router.push({
                  name: RouteNames.FORECAST_PARAMETER_MANAGEMENT,
                })
              "
            />
            <Button
              label="Berechnungsbaum"
              @click="
                router.push({
                  name: RouteNames.CALCULATION_TREE,
                })
              "
            />
          </template>
        </Card>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
  import { useRouter } from "vue-router";
  import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
  import { OauthRoles, useOauthRoles } from "@/composition-api/useOauthRoles";
  import { RouteNames } from "@/router/RouteNames";
  import Button from "primevue/button";
  import Card from "primevue/card";
  import InputText from "primevue/inputtext";
  import FloatLabel from "primevue/floatlabel";
  import { useBackendApi } from "@/composition-api/useBackendApi";
  import { useToast } from "primevue/usetoast";
  import { showErrorMessage } from "@/utils/errorHandler";
  import {
    WatchStopHandle,
    getCurrentInstance,
    onMounted,
    onUnmounted,
    ref,
    watch,
    computed,
  } from "vue";
  import { BackendApis } from "@/plugins/backendApiPlugin";

  const toast = useToast();
  const isAuthenticated = useIsAuthenticated();
  const currentInstance = getCurrentInstance();
  const isAdmin = computed(() => roles.value?.isAdmin ?? false);

  const router = useRouter();
  const roles = ref<OauthRoles>();
  const vueInstance = getCurrentInstance();
  const api = ref<BackendApis>();
  const getApi = (): BackendApis => {
    if (!api.value) {
      api.value = useBackendApi(vueInstance);
    }
    return api.value;
  };

  let unwatch: WatchStopHandle;
  onMounted(() => {
    unwatch = watch(
      isAuthenticated,
      (v) => {
        if (v) {
          roles.value = useOauthRoles(currentInstance);
        }
      },
      { immediate: true },
    );
  });

  onUnmounted(() => {
    unwatch?.();
  });

  const idsRaw = ref("");
  const isImporting = ref(false);

  function importRailium() {
    let railiumIds: number[] = idsRaw.value
      .split(",")
      .map((s) => Number.parseInt(s, 10));
    if (railiumIds.length == 0) {
      return;
    }

    isImporting.value = true;
    getApi()
      .railium.importEinsaetze({
        requestBody: railiumIds,
      })
      .then(() =>
        toast.add({ detail: "Hurray!", severity: "success", life: 2000 }),
      )
      .catch((e) => showErrorMessage(e, toast))
      .finally(() => (isImporting.value = false));
  }
</script>

<style scoped lang="scss">
  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem 2rem;
    flex-wrap: wrap;

    .menu-card {
      text-align: center;
    }

    .card-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-size: 1em;
      font-weight: bold;
    }

    button {
      margin: 0.5rem 0 0 1rem;
    }
  }
</style>
