/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BerechnungsKnoten } from './BerechnungsKnoten';
import {
    BerechnungsKnotenFromJSON,
    BerechnungsKnotenFromJSONTyped,
    BerechnungsKnotenToJSON,
} from './BerechnungsKnoten';

/**
 * 
 * @export
 * @interface BerechnungsBaumVeraenderung
 */
export interface BerechnungsBaumVeraenderung {
    /**
     * 
     * @type {Array<BerechnungsKnoten>}
     * @memberof BerechnungsBaumVeraenderung
     */
    knoten: Array<BerechnungsKnoten>;
    /**
     * 
     * @type {Array<BerechnungsKnoten>}
     * @memberof BerechnungsBaumVeraenderung
     */
    entfernen: Array<BerechnungsKnoten>;
}

/**
 * Check if a given object implements the BerechnungsBaumVeraenderung interface.
 */
export function instanceOfBerechnungsBaumVeraenderung(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "knoten" in value;
    isInstance = isInstance && "entfernen" in value;

    return isInstance;
}

export function BerechnungsBaumVeraenderungFromJSON(json: any): BerechnungsBaumVeraenderung {
    return BerechnungsBaumVeraenderungFromJSONTyped(json, false);
}

export function BerechnungsBaumVeraenderungFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerechnungsBaumVeraenderung {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'knoten': ((json['knoten'] as Array<any>).map(BerechnungsKnotenFromJSON)),
        'entfernen': ((json['entfernen'] as Array<any>).map(BerechnungsKnotenFromJSON)),
    };
}

export function BerechnungsBaumVeraenderungToJSON(value?: BerechnungsBaumVeraenderung | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'knoten': ((value.knoten as Array<any>).map(BerechnungsKnotenToJSON)),
        'entfernen': ((value.entfernen as Array<any>).map(BerechnungsKnotenToJSON)),
    };
}

