export async function handleHttpError(e: unknown): Promise<string> {
  let isAbortError =
    (e as any)?.name === "AbortError" ||
    (e as any)?.cause?.name === "AbortError";
  if (isAbortError) {
    // The request was aborted for some reason, so we do not care about that here.
    // One reason might be using the AbortController but also like reloading the page.
    let err = (e as any)?.cause ?? e;
    return Promise.reject(err.name + ": " + err.message);
  }
  const response = getResponse(e);
  if (response) {
    let body = await response.text();
    console.error(e);
    console.error("Body: " + body);

    return new Promise((resolve, reject) =>
      resolve(
        `URL: ${response.url}\nStatus: ${response.status}${
          body ? `\nBody: ${body}` : ``
        }`,
      ),
    );
  } else {
    console.error(e);
    if ((e as any).cause) {
      console.error((e as any).cause);
    }
    return new Promise((resolve, reject) => resolve(String(e)));
  }
}

function getResponse(e: unknown): Response | undefined {
  return (e as any).response;
}
