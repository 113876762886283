/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BerechnungsParameterWertQuelle } from './BerechnungsParameterWertQuelle';
import {
    BerechnungsParameterWertQuelleFromJSON,
    BerechnungsParameterWertQuelleFromJSONTyped,
    BerechnungsParameterWertQuelleToJSON,
} from './BerechnungsParameterWertQuelle';

/**
 * 
 * @export
 * @interface BerechnungsParameter
 */
export interface BerechnungsParameter {
    /**
     * 
     * @type {number}
     * @memberof BerechnungsParameter
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BerechnungsParameter
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BerechnungsParameter
     */
    beschreibung?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof BerechnungsParameter
     */
    tagIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof BerechnungsParameter
     */
    einheitId: number;
    /**
     * 
     * @type {BerechnungsParameterWertQuelle}
     * @memberof BerechnungsParameter
     */
    wertQuelle: BerechnungsParameterWertQuelle;
    /**
     * Wenn wahr, kann dieser Parameter in einer Auswahlbox ausgewählt und einem Umsatz hinzugefügt werden.
     * @type {boolean}
     * @memberof BerechnungsParameter
     */
    inAuswahlbox: boolean;
    /**
     * Parameter mit gleicher Nummer bilden eine Gruppe. Wird zur zeilenweisen Darstellung (inkl. Sortierung) verwendet.
     * @type {number}
     * @memberof BerechnungsParameter
     */
    gruppenNummer: number;
    /**
     * Gibt die Reihenfolge innerhalb einer Gruppe an. Wird zur spaltenweisen Darstellung verwendet.
     * @type {number}
     * @memberof BerechnungsParameter
     */
    sortierungInGruppe: number;
}

/**
 * Check if a given object implements the BerechnungsParameter interface.
 */
export function instanceOfBerechnungsParameter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "tagIds" in value;
    isInstance = isInstance && "einheitId" in value;
    isInstance = isInstance && "wertQuelle" in value;
    isInstance = isInstance && "inAuswahlbox" in value;
    isInstance = isInstance && "gruppenNummer" in value;
    isInstance = isInstance && "sortierungInGruppe" in value;

    return isInstance;
}

export function BerechnungsParameterFromJSON(json: any): BerechnungsParameter {
    return BerechnungsParameterFromJSONTyped(json, false);
}

export function BerechnungsParameterFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerechnungsParameter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'beschreibung': !exists(json, 'beschreibung') ? undefined : json['beschreibung'],
        'tagIds': json['tagIds'],
        'einheitId': json['einheitId'],
        'wertQuelle': BerechnungsParameterWertQuelleFromJSON(json['wertQuelle']),
        'inAuswahlbox': json['inAuswahlbox'],
        'gruppenNummer': json['gruppenNummer'],
        'sortierungInGruppe': json['sortierungInGruppe'],
    };
}

export function BerechnungsParameterToJSON(value?: BerechnungsParameter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'beschreibung': value.beschreibung,
        'tagIds': value.tagIds,
        'einheitId': value.einheitId,
        'wertQuelle': BerechnungsParameterWertQuelleToJSON(value.wertQuelle),
        'inAuswahlbox': value.inAuswahlbox,
        'gruppenNummer': value.gruppenNummer,
        'sortierungInGruppe': value.sortierungInGruppe,
    };
}

