/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BerechnungsParameterWert } from './BerechnungsParameterWert';
import {
    BerechnungsParameterWertFromJSON,
    BerechnungsParameterWertFromJSONTyped,
    BerechnungsParameterWertToJSON,
} from './BerechnungsParameterWert';

/**
 * 
 * @export
 * @interface UmsatzSpeichernRequest
 */
export interface UmsatzSpeichernRequest {
    /**
     * 
     * @type {number}
     * @memberof UmsatzSpeichernRequest
     */
    id: number;
    /**
     * Gesamtumsatz in Euro, falls von der Berechnung abweichend.
     * @type {number}
     * @memberof UmsatzSpeichernRequest
     */
    ueberschriebenerBetrag?: number;
    /**
     * 
     * @type {Array<BerechnungsParameterWert>}
     * @memberof UmsatzSpeichernRequest
     */
    parameterWerte: Array<BerechnungsParameterWert>;
    /**
     * 
     * @type {string}
     * @memberof UmsatzSpeichernRequest
     */
    maschinenGruppe: string;
    /**
     * 
     * @type {string}
     * @memberof UmsatzSpeichernRequest
     */
    datum: string;
    /**
     * Einsätze aufgrund deren sich eine Prognose geändert hat. Ändert sich ein Parameter von einem dieser Einsätze, muss dieser Umsatz erneut beurteilt werden.
     * @type {Array<number>}
     * @memberof UmsatzSpeichernRequest
     */
    referenzEinsatzIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof UmsatzSpeichernRequest
     */
    grund?: string;
}

/**
 * Check if a given object implements the UmsatzSpeichernRequest interface.
 */
export function instanceOfUmsatzSpeichernRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "parameterWerte" in value;
    isInstance = isInstance && "maschinenGruppe" in value;
    isInstance = isInstance && "datum" in value;
    isInstance = isInstance && "referenzEinsatzIds" in value;

    return isInstance;
}

export function UmsatzSpeichernRequestFromJSON(json: any): UmsatzSpeichernRequest {
    return UmsatzSpeichernRequestFromJSONTyped(json, false);
}

export function UmsatzSpeichernRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UmsatzSpeichernRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'ueberschriebenerBetrag': !exists(json, 'ueberschriebenerBetrag') ? undefined : json['ueberschriebenerBetrag'],
        'parameterWerte': ((json['parameterWerte'] as Array<any>).map(BerechnungsParameterWertFromJSON)),
        'maschinenGruppe': json['maschinenGruppe'],
        'datum': json['datum'],
        'referenzEinsatzIds': json['referenzEinsatzIds'],
        'grund': !exists(json, 'grund') ? undefined : json['grund'],
    };
}

export function UmsatzSpeichernRequestToJSON(value?: UmsatzSpeichernRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'ueberschriebenerBetrag': value.ueberschriebenerBetrag,
        'parameterWerte': ((value.parameterWerte as Array<any>).map(BerechnungsParameterWertToJSON)),
        'maschinenGruppe': value.maschinenGruppe,
        'datum': value.datum,
        'referenzEinsatzIds': value.referenzEinsatzIds,
        'grund': value.grund,
    };
}

