/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RailiumEinsatz
 */
export interface RailiumEinsatz {
    /**
     * 
     * @type {number}
     * @memberof RailiumEinsatz
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    titel: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    bis: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    maschinengruppe: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    einsatzart: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    angebotsnummer?: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    auftragsnummer?: string;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    kunde?: string;
    /**
     * 
     * @type {number}
     * @memberof RailiumEinsatz
     */
    laufmeter: number;
    /**
     * 
     * @type {number}
     * @memberof RailiumEinsatz
     */
    stueck: number;
    /**
     * 
     * @type {string}
     * @memberof RailiumEinsatz
     */
    oebbZusatzinfo: string;
    /**
     * 
     * @type {number}
     * @memberof RailiumEinsatz
     */
    anzahlKranhelfer: number;
    /**
     * 
     * @type {number}
     * @memberof RailiumEinsatz
     */
    gruppeId?: number;
}

/**
 * Check if a given object implements the RailiumEinsatz interface.
 */
export function instanceOfRailiumEinsatz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "titel" in value;
    isInstance = isInstance && "von" in value;
    isInstance = isInstance && "bis" in value;
    isInstance = isInstance && "maschinengruppe" in value;
    isInstance = isInstance && "einsatzart" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "laufmeter" in value;
    isInstance = isInstance && "stueck" in value;
    isInstance = isInstance && "oebbZusatzinfo" in value;
    isInstance = isInstance && "anzahlKranhelfer" in value;

    return isInstance;
}

export function RailiumEinsatzFromJSON(json: any): RailiumEinsatz {
    return RailiumEinsatzFromJSONTyped(json, false);
}

export function RailiumEinsatzFromJSONTyped(json: any, ignoreDiscriminator: boolean): RailiumEinsatz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'titel': json['titel'],
        'von': json['von'],
        'bis': json['bis'],
        'maschinengruppe': json['maschinengruppe'],
        'einsatzart': json['einsatzart'],
        'region': !exists(json, 'region') ? undefined : json['region'],
        'status': json['status'],
        'angebotsnummer': !exists(json, 'angebotsnummer') ? undefined : json['angebotsnummer'],
        'auftragsnummer': !exists(json, 'auftragsnummer') ? undefined : json['auftragsnummer'],
        'kunde': !exists(json, 'kunde') ? undefined : json['kunde'],
        'laufmeter': json['laufmeter'],
        'stueck': json['stueck'],
        'oebbZusatzinfo': json['oebbZusatzinfo'],
        'anzahlKranhelfer': json['anzahlKranhelfer'],
        'gruppeId': !exists(json, 'gruppeId') ? undefined : json['gruppeId'],
    };
}

export function RailiumEinsatzToJSON(value?: RailiumEinsatz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'titel': value.titel,
        'von': value.von,
        'bis': value.bis,
        'maschinengruppe': value.maschinengruppe,
        'einsatzart': value.einsatzart,
        'region': value.region,
        'status': value.status,
        'angebotsnummer': value.angebotsnummer,
        'auftragsnummer': value.auftragsnummer,
        'kunde': value.kunde,
        'laufmeter': value.laufmeter,
        'stueck': value.stueck,
        'oebbZusatzinfo': value.oebbZusatzinfo,
        'anzahlKranhelfer': value.anzahlKranhelfer,
        'gruppeId': value.gruppeId,
    };
}

