/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Prognosestatus:
 *   * `UNGUELTIG_AUTOMATISCH` - Die Berechnung des Umsatzes ist nicht möglich (z.B. fehlende kalkulatorische Ansätze beim Jahreswechsel)
 *   * `UNGUELTIG_MANUELL` - Die (erneute) Berechnung eines manuell bearbeiteten Umsatzes ist nicht möglich
 *   * `ABGESCHLOSSEN_AUTOMATISCH`- Umsatz wurde automatisch berechnet
 *   * `OFFEN_MANUELL` - Ein Einsatz des Umsatzes enthält ein Signalwort, weshalb dieser vom Benutzer bearbeitet werden muss.
 *     Wenn sich in Relation stehende Einsatzdaten von einem abgeschlossenen Umsatz ändern, ist der Status erneut OFFEN (der Umsatz enthält die Eigenschaft `manuellAbgeschlossen`, siehe `ABGESCHLOSSEN_MANUELL`).
 *   * `ABGESCHLOSSEN_MANUELL`- Umsatz wurde von Benutzer abgeschlossen (der Umsatz enthält die Eigenschaft `manuellAbgeschlossen`)
 *   * `BEARBEITET_OFFEN` - In Relation stehende Einsatzdaten haben sich geändert, weshalb ein bereits bearbeiteter Umsatz zu prüfen ist
 * 
 * @export
 */
export const UmsatzPrognoseStatus = {
    UngueltigAutomatisch: 'UNGUELTIG_AUTOMATISCH',
    UngueltigManuell: 'UNGUELTIG_MANUELL',
    AbgeschlossenAutomatisch: 'ABGESCHLOSSEN_AUTOMATISCH',
    AbgeschlossenManuell: 'ABGESCHLOSSEN_MANUELL',
    OffenManuell: 'OFFEN_MANUELL'
} as const;
export type UmsatzPrognoseStatus = typeof UmsatzPrognoseStatus[keyof typeof UmsatzPrognoseStatus];


export function UmsatzPrognoseStatusFromJSON(json: any): UmsatzPrognoseStatus {
    return UmsatzPrognoseStatusFromJSONTyped(json, false);
}

export function UmsatzPrognoseStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UmsatzPrognoseStatus {
    return json as UmsatzPrognoseStatus;
}

export function UmsatzPrognoseStatusToJSON(value?: UmsatzPrognoseStatus | null): any {
    return value as any;
}

