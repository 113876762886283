/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RailiumEinsaetzeAusschnitt,
  RailiumEinsatz,
} from '../models/index';
import {
    RailiumEinsaetzeAusschnittFromJSON,
    RailiumEinsaetzeAusschnittToJSON,
    RailiumEinsatzFromJSON,
    RailiumEinsatzToJSON,
} from '../models/index';

export interface ImportEinsaetzeRequest {
    requestBody: Array<number>;
}

export interface RailiumEinsaetzeRequest {
    filter: string;
}

export interface RailiumEinsaetzeAusschnittRequest {
    seite: number;
    anzahl: number;
    filter: string;
}

/**
 * RailiumApi - interface
 * 
 * @export
 * @interface RailiumApiInterface
 */
export interface RailiumApiInterface {
    /**
     * 
     * @summary Lädt die angegeben Einsätze aus dem Railium-Gateway in die Datenbank.
     * @param {Array<number>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailiumApiInterface
     */
    importEinsaetzeRaw(requestParameters: ImportEinsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Lädt die angegeben Einsätze aus dem Railium-Gateway in die Datenbank.
     */
    importEinsaetze(requestParameters: ImportEinsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Gibt alle Railium-Einsätze für den übergebenen RSQL-Filter zurück.
     * @param {string} filter 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailiumApiInterface
     */
    railiumEinsaetzeRaw(requestParameters: RailiumEinsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RailiumEinsatz>>>;

    /**
     * Gibt alle Railium-Einsätze für den übergebenen RSQL-Filter zurück.
     */
    railiumEinsaetze(requestParameters: RailiumEinsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RailiumEinsatz>>;

    /**
     * 
     * @summary Gibt einen Auschnitt von Railium-Einsätzen für den übergebenen RSQL-Filter zurück.
     * @param {number} seite Seitennummer bei mehreren Seiten.
     * @param {number} anzahl Gibt die Anzahl der Elemente pro Seite an.
     * @param {string} filter Gibt den RSQL-Filter für die gewünschten Elemente an.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailiumApiInterface
     */
    railiumEinsaetzeAusschnittRaw(requestParameters: RailiumEinsaetzeAusschnittRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RailiumEinsaetzeAusschnitt>>;

    /**
     * Gibt einen Auschnitt von Railium-Einsätzen für den übergebenen RSQL-Filter zurück.
     */
    railiumEinsaetzeAusschnitt(requestParameters: RailiumEinsaetzeAusschnittRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RailiumEinsaetzeAusschnitt>;

}

/**
 * 
 */
export class RailiumApi extends runtime.BaseAPI implements RailiumApiInterface {

    /**
     * Lädt die angegeben Einsätze aus dem Railium-Gateway in die Datenbank.
     */
    async importEinsaetzeRaw(requestParameters: ImportEinsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling importEinsaetze.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/railium/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Lädt die angegeben Einsätze aus dem Railium-Gateway in die Datenbank.
     */
    async importEinsaetze(requestParameters: ImportEinsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.importEinsaetzeRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt alle Railium-Einsätze für den übergebenen RSQL-Filter zurück.
     */
    async railiumEinsaetzeRaw(requestParameters: RailiumEinsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RailiumEinsatz>>> {
        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling railiumEinsaetze.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/railium/einsaetze`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RailiumEinsatzFromJSON));
    }

    /**
     * Gibt alle Railium-Einsätze für den übergebenen RSQL-Filter zurück.
     */
    async railiumEinsaetze(requestParameters: RailiumEinsaetzeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RailiumEinsatz>> {
        const response = await this.railiumEinsaetzeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt einen Auschnitt von Railium-Einsätzen für den übergebenen RSQL-Filter zurück.
     */
    async railiumEinsaetzeAusschnittRaw(requestParameters: RailiumEinsaetzeAusschnittRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RailiumEinsaetzeAusschnitt>> {
        if (requestParameters.seite === null || requestParameters.seite === undefined) {
            throw new runtime.RequiredError('seite','Required parameter requestParameters.seite was null or undefined when calling railiumEinsaetzeAusschnitt.');
        }

        if (requestParameters.anzahl === null || requestParameters.anzahl === undefined) {
            throw new runtime.RequiredError('anzahl','Required parameter requestParameters.anzahl was null or undefined when calling railiumEinsaetzeAusschnitt.');
        }

        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling railiumEinsaetzeAusschnitt.');
        }

        const queryParameters: any = {};

        if (requestParameters.seite !== undefined) {
            queryParameters['seite'] = requestParameters.seite;
        }

        if (requestParameters.anzahl !== undefined) {
            queryParameters['anzahl'] = requestParameters.anzahl;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/railium/einsaetze/ausschnitt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RailiumEinsaetzeAusschnittFromJSON(jsonValue));
    }

    /**
     * Gibt einen Auschnitt von Railium-Einsätzen für den übergebenen RSQL-Filter zurück.
     */
    async railiumEinsaetzeAusschnitt(requestParameters: RailiumEinsaetzeAusschnittRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RailiumEinsaetzeAusschnitt> {
        const response = await this.railiumEinsaetzeAusschnittRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
