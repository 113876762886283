/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RailiumEinsatz } from './RailiumEinsatz';
import {
    RailiumEinsatzFromJSON,
    RailiumEinsatzFromJSONTyped,
    RailiumEinsatzToJSON,
} from './RailiumEinsatz';

/**
 * 
 * @export
 * @interface RailiumEinsaetzeAusschnitt
 */
export interface RailiumEinsaetzeAusschnitt {
    /**
     * Anzahl der zurückgegebenen Einsätze.
     * @type {number}
     * @memberof RailiumEinsaetzeAusschnitt
     */
    anzahlEinsaetze: number;
    /**
     * Anzahl der für den Filter zur Verfügung stehenden Einsätze.
     * @type {number}
     * @memberof RailiumEinsaetzeAusschnitt
     */
    anzahlEinsaetzeGesamt: number;
    /**
     * 
     * @type {Array<RailiumEinsatz>}
     * @memberof RailiumEinsaetzeAusschnitt
     */
    einsaetze: Array<RailiumEinsatz>;
}

/**
 * Check if a given object implements the RailiumEinsaetzeAusschnitt interface.
 */
export function instanceOfRailiumEinsaetzeAusschnitt(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "anzahlEinsaetze" in value;
    isInstance = isInstance && "anzahlEinsaetzeGesamt" in value;
    isInstance = isInstance && "einsaetze" in value;

    return isInstance;
}

export function RailiumEinsaetzeAusschnittFromJSON(json: any): RailiumEinsaetzeAusschnitt {
    return RailiumEinsaetzeAusschnittFromJSONTyped(json, false);
}

export function RailiumEinsaetzeAusschnittFromJSONTyped(json: any, ignoreDiscriminator: boolean): RailiumEinsaetzeAusschnitt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anzahlEinsaetze': json['anzahlEinsaetze'],
        'anzahlEinsaetzeGesamt': json['anzahlEinsaetzeGesamt'],
        'einsaetze': ((json['einsaetze'] as Array<any>).map(RailiumEinsatzFromJSON)),
    };
}

export function RailiumEinsaetzeAusschnittToJSON(value?: RailiumEinsaetzeAusschnitt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anzahlEinsaetze': value.anzahlEinsaetze,
        'anzahlEinsaetzeGesamt': value.anzahlEinsaetzeGesamt,
        'einsaetze': ((value.einsaetze as Array<any>).map(RailiumEinsatzToJSON)),
    };
}

