import { App, Plugin, Ref, ref } from "vue";
import {
  Configuration as GraphConfiguration,
  ConfigurationParameters,
  MeApi,
  UserApi,
} from "@apis/msgraph";
import { AccessToken, isValidAccessToken } from "./apiHelpers";
import {
  BaseApis,
  setupCallWhenTokenInitialized,
} from "@/plugins/baseApiPlugin";

export interface MsGraphApis extends BaseApis {
  me: MeApi;
  users: UserApi;
}

export const apiPlugin: Plugin = {
  install: (app: App) => {
    app.config.globalProperties.$msGraphApiAccessToken = ref<AccessToken>();
    app.config.globalProperties.$msGraphApiAccessTokenLock = ref(false);

    const accessTokenToHeader: (at?: AccessToken) => string = (at) => {
      if (isValidAccessToken(at)) {
        return "Bearer " + at!.accessToken;
      } else {
        throw new Error("Missing access token");
      }
    };

    const tokenInitialized = ref(false);

    let configBase: ConfigurationParameters = {};
    const graphConfig = new GraphConfiguration({
      ...configBase,
      accessToken: () =>
        accessTokenToHeader(
          app.config.globalProperties.$msGraphApiAccessToken.value,
        ),
    });

    app.config.globalProperties.$msGraphApis = {
      me: new MeApi(graphConfig),
      users: new UserApi(graphConfig),
      isTokenInitialized: tokenInitialized,
      isTokenCurrentlyValid: ref(false),
      callWhenTokenInitialized: setupCallWhenTokenInitialized(tokenInitialized),
    };
  },
};

declare module "vue" {
  interface ComponentCustomProperties {
    $msGraphApiAccessToken: Ref<AccessToken | undefined>;
    $msGraphApiAccessTokenLock: Ref<boolean>;
    $msGraphApis: MsGraphApis;
  }
}
