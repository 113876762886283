/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Einheit
 */
export interface Einheit {
    /**
     * 
     * @type {number}
     * @memberof Einheit
     */
    id: number;
    /**
     * Ein für i18n formatierter String [0 | 1 | >1].
     * @type {string}
     * @memberof Einheit
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Einheit
     */
    abkuerzung: string;
    /**
     * 
     * @type {number}
     * @memberof Einheit
     */
    kommaStellen: number;
}

/**
 * Check if a given object implements the Einheit interface.
 */
export function instanceOfEinheit(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "abkuerzung" in value;
    isInstance = isInstance && "kommaStellen" in value;

    return isInstance;
}

export function EinheitFromJSON(json: any): Einheit {
    return EinheitFromJSONTyped(json, false);
}

export function EinheitFromJSONTyped(json: any, ignoreDiscriminator: boolean): Einheit {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'abkuerzung': json['abkuerzung'],
        'kommaStellen': json['kommaStellen'],
    };
}

export function EinheitToJSON(value?: Einheit | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'abkuerzung': value.abkuerzung,
        'kommaStellen': value.kommaStellen,
    };
}

