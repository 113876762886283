/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrognoseGruppeTyp } from './PrognoseGruppeTyp';
import {
    PrognoseGruppeTypFromJSON,
    PrognoseGruppeTypFromJSONTyped,
    PrognoseGruppeTypToJSON,
} from './PrognoseGruppeTyp';
import type { UmsatzBerechnungsParameter } from './UmsatzBerechnungsParameter';
import {
    UmsatzBerechnungsParameterFromJSON,
    UmsatzBerechnungsParameterFromJSONTyped,
    UmsatzBerechnungsParameterToJSON,
} from './UmsatzBerechnungsParameter';
import type { UmsatzHistorie } from './UmsatzHistorie';
import {
    UmsatzHistorieFromJSON,
    UmsatzHistorieFromJSONTyped,
    UmsatzHistorieToJSON,
} from './UmsatzHistorie';
import type { UmsatzPrognoseStatus } from './UmsatzPrognoseStatus';
import {
    UmsatzPrognoseStatusFromJSON,
    UmsatzPrognoseStatusFromJSONTyped,
    UmsatzPrognoseStatusToJSON,
} from './UmsatzPrognoseStatus';
import type { UmsatzQuelle } from './UmsatzQuelle';
import {
    UmsatzQuelleFromJSON,
    UmsatzQuelleFromJSONTyped,
    UmsatzQuelleToJSON,
} from './UmsatzQuelle';

/**
 * 
 * @export
 * @interface Umsatz
 */
export interface Umsatz {
    /**
     * 
     * @type {number}
     * @memberof Umsatz
     */
    id: number;
    /**
     * 
     * @type {PrognoseGruppeTyp}
     * @memberof Umsatz
     */
    prognoseGruppeTyp?: PrognoseGruppeTyp;
    /**
     * 
     * @type {UmsatzPrognoseStatus}
     * @memberof Umsatz
     */
    prognoseStatus: UmsatzPrognoseStatus;
    /**
     * 
     * @type {string}
     * @memberof Umsatz
     */
    anmerkungPrognoseStatus?: string;
    /**
     * Gesamtumsatz in Euro.
     * @type {number}
     * @memberof Umsatz
     */
    betrag: number;
    /**
     * 
     * @type {UmsatzQuelle}
     * @memberof Umsatz
     */
    quelle: UmsatzQuelle;
    /**
     * 
     * @type {Array<UmsatzBerechnungsParameter>}
     * @memberof Umsatz
     */
    berechnungsParameter: Array<UmsatzBerechnungsParameter>;
    /**
     * 
     * @type {string}
     * @memberof Umsatz
     */
    maschinenGruppe: string;
    /**
     * 
     * @type {string}
     * @memberof Umsatz
     */
    datum: string;
    /**
     * Einsätze mit den Basisdaten aus denen sich ein Umsatz berechnet (z.B. Einsatzgruppe für gemeinsame Mindestverrechnung).
     * @type {Array<number>}
     * @memberof Umsatz
     */
    basisEinsatzIds?: Array<number>;
    /**
     * Berichte mit den Ist-Werten der Einsätze.
     * @type {Array<number>}
     * @memberof Umsatz
     */
    berichtIds: Array<number>;
    /**
     * Einsätze aufgrund deren sich eine Prognose geändert hat. Ändert sich ein Parameter von einem dieser Einsätze, muss dieser Umsatz erneut beurteilt werden.
     * @type {Array<number>}
     * @memberof Umsatz
     */
    referenzEinsatzIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Umsatz
     */
    grund?: string;
    /**
     * Gibt einen Hinweis darauf, dass dieser Umsatz einmal manuell abgeschlossen wurde (unabhängig vom aktuellen Prognosestatus).
     * @type {boolean}
     * @memberof Umsatz
     */
    manuellAbgeschlossen: boolean;
    /**
     * 
     * @type {UmsatzHistorie}
     * @memberof Umsatz
     */
    historie: UmsatzHistorie;
}

/**
 * Check if a given object implements the Umsatz interface.
 */
export function instanceOfUmsatz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "prognoseStatus" in value;
    isInstance = isInstance && "betrag" in value;
    isInstance = isInstance && "quelle" in value;
    isInstance = isInstance && "berechnungsParameter" in value;
    isInstance = isInstance && "maschinenGruppe" in value;
    isInstance = isInstance && "datum" in value;
    isInstance = isInstance && "berichtIds" in value;
    isInstance = isInstance && "referenzEinsatzIds" in value;
    isInstance = isInstance && "manuellAbgeschlossen" in value;
    isInstance = isInstance && "historie" in value;

    return isInstance;
}

export function UmsatzFromJSON(json: any): Umsatz {
    return UmsatzFromJSONTyped(json, false);
}

export function UmsatzFromJSONTyped(json: any, ignoreDiscriminator: boolean): Umsatz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'prognoseGruppeTyp': !exists(json, 'prognoseGruppeTyp') ? undefined : PrognoseGruppeTypFromJSON(json['prognoseGruppeTyp']),
        'prognoseStatus': UmsatzPrognoseStatusFromJSON(json['prognoseStatus']),
        'anmerkungPrognoseStatus': !exists(json, 'anmerkungPrognoseStatus') ? undefined : json['anmerkungPrognoseStatus'],
        'betrag': json['betrag'],
        'quelle': UmsatzQuelleFromJSON(json['quelle']),
        'berechnungsParameter': ((json['berechnungsParameter'] as Array<any>).map(UmsatzBerechnungsParameterFromJSON)),
        'maschinenGruppe': json['maschinenGruppe'],
        'datum': json['datum'],
        'basisEinsatzIds': !exists(json, 'basisEinsatzIds') ? undefined : json['basisEinsatzIds'],
        'berichtIds': json['berichtIds'],
        'referenzEinsatzIds': json['referenzEinsatzIds'],
        'grund': !exists(json, 'grund') ? undefined : json['grund'],
        'manuellAbgeschlossen': json['manuellAbgeschlossen'],
        'historie': UmsatzHistorieFromJSON(json['historie']),
    };
}

export function UmsatzToJSON(value?: Umsatz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'prognoseGruppeTyp': PrognoseGruppeTypToJSON(value.prognoseGruppeTyp),
        'prognoseStatus': UmsatzPrognoseStatusToJSON(value.prognoseStatus),
        'anmerkungPrognoseStatus': value.anmerkungPrognoseStatus,
        'betrag': value.betrag,
        'quelle': UmsatzQuelleToJSON(value.quelle),
        'berechnungsParameter': ((value.berechnungsParameter as Array<any>).map(UmsatzBerechnungsParameterToJSON)),
        'maschinenGruppe': value.maschinenGruppe,
        'datum': value.datum,
        'basisEinsatzIds': value.basisEinsatzIds,
        'berichtIds': value.berichtIds,
        'referenzEinsatzIds': value.referenzEinsatzIds,
        'grund': value.grund,
        'manuellAbgeschlossen': value.manuellAbgeschlossen,
        'historie': UmsatzHistorieToJSON(value.historie),
    };
}

