/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BerechnungsKnotenTyp = {
    OperatorKnoten: 'OperatorKnoten',
    ParameterKnoten: 'ParameterKnoten'
} as const;
export type BerechnungsKnotenTyp = typeof BerechnungsKnotenTyp[keyof typeof BerechnungsKnotenTyp];


export function BerechnungsKnotenTypFromJSON(json: any): BerechnungsKnotenTyp {
    return BerechnungsKnotenTypFromJSONTyped(json, false);
}

export function BerechnungsKnotenTypFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerechnungsKnotenTyp {
    return json as BerechnungsKnotenTyp;
}

export function BerechnungsKnotenTypToJSON(value?: BerechnungsKnotenTyp | null): any {
    return value as any;
}

