/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TagSpeichernRequest
 */
export interface TagSpeichernRequest {
    /**
     * 
     * @type {string}
     * @memberof TagSpeichernRequest
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof TagSpeichernRequest
     */
    typId: number;
    /**
     * 
     * @type {boolean}
     * @memberof TagSpeichernRequest
     */
    optional: boolean;
}

/**
 * Check if a given object implements the TagSpeichernRequest interface.
 */
export function instanceOfTagSpeichernRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "typId" in value;
    isInstance = isInstance && "optional" in value;

    return isInstance;
}

export function TagSpeichernRequestFromJSON(json: any): TagSpeichernRequest {
    return TagSpeichernRequestFromJSONTyped(json, false);
}

export function TagSpeichernRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagSpeichernRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'typId': json['typId'],
        'optional': json['optional'],
    };
}

export function TagSpeichernRequestToJSON(value?: TagSpeichernRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'typId': value.typId,
        'optional': value.optional,
    };
}

