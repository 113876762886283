/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrognoseGruppeTyp } from './PrognoseGruppeTyp';
import {
    PrognoseGruppeTypFromJSON,
    PrognoseGruppeTypFromJSONTyped,
    PrognoseGruppeTypToJSON,
} from './PrognoseGruppeTyp';

/**
 * 
 * @export
 * @interface PrognoseGruppe
 */
export interface PrognoseGruppe {
    /**
     * 
     * @type {number}
     * @memberof PrognoseGruppe
     */
    id: number;
    /**
     * 
     * @type {PrognoseGruppeTyp}
     * @memberof PrognoseGruppe
     */
    typ: PrognoseGruppeTyp;
    /**
     * 
     * @type {string}
     * @memberof PrognoseGruppe
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PrognoseGruppe
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof PrognoseGruppe
     */
    bis?: string;
}

/**
 * Check if a given object implements the PrognoseGruppe interface.
 */
export function instanceOfPrognoseGruppe(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "typ" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "von" in value;

    return isInstance;
}

export function PrognoseGruppeFromJSON(json: any): PrognoseGruppe {
    return PrognoseGruppeFromJSONTyped(json, false);
}

export function PrognoseGruppeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrognoseGruppe {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'typ': PrognoseGruppeTypFromJSON(json['typ']),
        'name': json['name'],
        'von': json['von'],
        'bis': !exists(json, 'bis') ? undefined : json['bis'],
    };
}

export function PrognoseGruppeToJSON(value?: PrognoseGruppe | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'typ': PrognoseGruppeTypToJSON(value.typ),
        'name': value.name,
        'von': value.von,
        'bis': value.bis,
    };
}

