/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BerechnungsOperatorId = {
    Addition: 'ADDITION',
    Subtraktion: 'SUBTRAKTION',
    Multiplikation: 'MULTIPLIKATION',
    Division: 'DIVISION',
    Maximum: 'MAXIMUM',
    Minimum: 'MINIMUM'
} as const;
export type BerechnungsOperatorId = typeof BerechnungsOperatorId[keyof typeof BerechnungsOperatorId];


export function BerechnungsOperatorIdFromJSON(json: any): BerechnungsOperatorId {
    return BerechnungsOperatorIdFromJSONTyped(json, false);
}

export function BerechnungsOperatorIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerechnungsOperatorId {
    return json as BerechnungsOperatorId;
}

export function BerechnungsOperatorIdToJSON(value?: BerechnungsOperatorId | null): any {
    return value as any;
}

