/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Umsatz } from './Umsatz';
import {
    UmsatzFromJSON,
    UmsatzFromJSONTyped,
    UmsatzToJSON,
} from './Umsatz';

/**
 * 
 * @export
 * @interface UmsaetzeAusschnitt
 */
export interface UmsaetzeAusschnitt {
    /**
     * Anzahl der zurückgegebenen Umsätze.
     * @type {number}
     * @memberof UmsaetzeAusschnitt
     */
    anzahlUmsaetze: number;
    /**
     * Anzahl der für den Filter zur Verfügung stehenden Umsätze.
     * @type {number}
     * @memberof UmsaetzeAusschnitt
     */
    anzahlUmsaetzeGesamt: number;
    /**
     * 
     * @type {Array<Umsatz>}
     * @memberof UmsaetzeAusschnitt
     */
    umsaetze: Array<Umsatz>;
}

/**
 * Check if a given object implements the UmsaetzeAusschnitt interface.
 */
export function instanceOfUmsaetzeAusschnitt(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "anzahlUmsaetze" in value;
    isInstance = isInstance && "anzahlUmsaetzeGesamt" in value;
    isInstance = isInstance && "umsaetze" in value;

    return isInstance;
}

export function UmsaetzeAusschnittFromJSON(json: any): UmsaetzeAusschnitt {
    return UmsaetzeAusschnittFromJSONTyped(json, false);
}

export function UmsaetzeAusschnittFromJSONTyped(json: any, ignoreDiscriminator: boolean): UmsaetzeAusschnitt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anzahlUmsaetze': json['anzahlUmsaetze'],
        'anzahlUmsaetzeGesamt': json['anzahlUmsaetzeGesamt'],
        'umsaetze': ((json['umsaetze'] as Array<any>).map(UmsatzFromJSON)),
    };
}

export function UmsaetzeAusschnittToJSON(value?: UmsaetzeAusschnitt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anzahlUmsaetze': value.anzahlUmsaetze,
        'anzahlUmsaetzeGesamt': value.anzahlUmsaetzeGesamt,
        'umsaetze': ((value.umsaetze as Array<any>).map(UmsatzToJSON)),
    };
}

