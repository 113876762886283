/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BerechnungsKnoten } from './BerechnungsKnoten';
import {
    BerechnungsKnotenFromJSON,
    BerechnungsKnotenFromJSONTyped,
    BerechnungsKnotenToJSON,
} from './BerechnungsKnoten';
import type { BerechnungsKnotenTyp } from './BerechnungsKnotenTyp';
import {
    BerechnungsKnotenTypFromJSON,
    BerechnungsKnotenTypFromJSONTyped,
    BerechnungsKnotenTypToJSON,
} from './BerechnungsKnotenTyp';
import type { BerechnungsOperatorId } from './BerechnungsOperatorId';
import {
    BerechnungsOperatorIdFromJSON,
    BerechnungsOperatorIdFromJSONTyped,
    BerechnungsOperatorIdToJSON,
} from './BerechnungsOperatorId';

/**
 * Ein Knoten mit Operator (plus, minus, ...) und linker und rechter Seite.
 * @export
 * @interface OperatorKnoten
 */
export interface OperatorKnoten {
    /**
     * 
     * @type {number}
     * @memberof OperatorKnoten
     */
    id: number;
    /**
     * 
     * @type {BerechnungsOperatorId}
     * @memberof OperatorKnoten
     */
    operator: BerechnungsOperatorId;
    /**
     * 
     * @type {BerechnungsKnoten}
     * @memberof OperatorKnoten
     */
    links: BerechnungsKnoten;
    /**
     * 
     * @type {BerechnungsKnoten}
     * @memberof OperatorKnoten
     */
    rechts: BerechnungsKnoten;
    /**
     * 
     * @type {BerechnungsKnotenTyp}
     * @memberof OperatorKnoten
     */
    knotenTyp: BerechnungsKnotenTyp;
    /**
     * 
     * @type {number}
     * @memberof OperatorKnoten
     */
    uebergeordnetId?: number;
}

/**
 * Check if a given object implements the OperatorKnoten interface.
 */
export function instanceOfOperatorKnoten(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "operator" in value;
    isInstance = isInstance && "links" in value;
    isInstance = isInstance && "rechts" in value;
    isInstance = isInstance && "knotenTyp" in value;

    return isInstance;
}

export function OperatorKnotenFromJSON(json: any): OperatorKnoten {
    return OperatorKnotenFromJSONTyped(json, false);
}

export function OperatorKnotenFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperatorKnoten {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'operator': BerechnungsOperatorIdFromJSON(json['operator']),
        'links': BerechnungsKnotenFromJSON(json['links']),
        'rechts': BerechnungsKnotenFromJSON(json['rechts']),
        'knotenTyp': BerechnungsKnotenTypFromJSON(json['knotenTyp']),
        'uebergeordnetId': !exists(json, 'uebergeordnetId') ? undefined : json['uebergeordnetId'],
    };
}

export function OperatorKnotenToJSON(value?: OperatorKnoten | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'operator': BerechnungsOperatorIdToJSON(value.operator),
        'links': BerechnungsKnotenToJSON(value.links),
        'rechts': BerechnungsKnotenToJSON(value.rechts),
        'knotenTyp': BerechnungsKnotenTypToJSON(value.knotenTyp),
        'uebergeordnetId': value.uebergeordnetId,
    };
}

