<template>
  <div class="navbar-container">
    <div class="logo-hl-container">
      <router-link to="/">
        <img src="/images/logo_rsrg_inverted.svg" alt="BBW-RSRG-Logo" />
      </router-link>
      <h1 v-if="route.meta.headline">{{ route.meta.headline }}</h1>
    </div>

    <div>
      <Button
        link
        class="button-size"
        v-if="isAuthenticated"
        type="button"
        @click="toggle"
        label="Profil"
        ><img
          v-if="userPhoto"
          class="profile-picture"
          :src="userPhoto"
          alt="Profilbild" />

        <div v-else class="pi pi-user profile-picture-empty"></div
      ></Button>

      <Popover ref="logoutPopover">
        <div class="overlay-container">
          <div class="user-info">
            {{ userDetails?.displayName }}
            <div class="job-name-container">
              {{ userDetails?.jobTitle }} <br />
              {{ userDetails?.companyName }}
            </div>
          </div>
          <Avatar
            v-if="userPhoto"
            class="avatar-design"
            :image="userPhoto"
            shape="circle"
          ></Avatar>
          <Avatar
            v-else
            class="avatar-design avatar-profile pi pi-user"
          ></Avatar>
        </div>

        <div class="logout-container">
          <Button @click="logoutRedirect"
            ><i class="pi pi-sign-out"></i> Abmelden</Button
          >
        </div>
      </Popover>

      <div>
        <SignInButton v-if="!isAuthenticated" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
  import SignInButton from "./SignInButton.vue";
  import {
    Ref,
    WatchStopHandle,
    computed,
    getCurrentInstance,
    onMounted,
    onUnmounted,
    ref,
    watch,
  } from "vue";
  import { showProductionSystemWarning } from "@/utils/devutils";
  import { useRoute } from "vue-router";
  import UserInfo from "../utils/UserInfo";
  import Button from "primevue/button";
  import { useMsal } from "@/composition-api/useMsal";
  import Avatar from "primevue/avatar";
  import { useUserProfile } from "@/composition-api/useUserProfile";
  import Popover from "primevue/popover";

  const isAuthenticated = useIsAuthenticated();
  const navbar = ref<HTMLElement>();
  const route = useRoute();
  const currentInstance = getCurrentInstance();

  const currentUserId = ref<string>();
  const getUserInfo = ref<(id: string) => Ref<UserInfo | undefined>>();
  const getUserPhoto = ref<(id: string) => Ref<string | undefined>>();

  const userDetails = computed(() => {
    if (currentUserId.value) {
      return getUserInfo.value!(currentUserId.value).value;
    } else {
      return undefined;
    }
  });
  const userPhoto = computed(() => {
    if (currentUserId.value) {
      return getUserPhoto.value!(currentUserId.value).value;
    } else {
      return undefined;
    }
  });

  watch(
    [showProductionSystemWarning, navbar],
    () => {
      if (showProductionSystemWarning.value && navbar.value) {
        navbar.value.style.backgroundColor = "rgb(255 105 72)";
      }
    },
    { immediate: true },
  );

  var unwatchAuth: WatchStopHandle;
  onMounted(() => {
    unwatchAuth = watch(
      isAuthenticated,
      () => {
        if (isAuthenticated.value) {
          const up = useUserProfile(currentInstance);
          getUserInfo.value = up.getUserInfo;
          getUserPhoto.value = up.getUserPhoto;
          watch(up.currentUserId, (v) => (currentUserId.value = v), {
            immediate: true,
          });
        }
      },
      { immediate: true },
    );
  });

  onUnmounted(() => {
    unwatchAuth?.();
  });

  const { instance } = useMsal();

  const logoutRedirect = () => {
    instance.logoutRedirect();
  };

  const logoutPopover = ref<typeof Popover>();

  const toggle = (event: MouseEvent) => {
    logoutPopover.value?.toggle(event);
  };
</script>

<style scoped lang="scss">
  #authButton {
    margin-left: auto;
  }

  .navbar-container {
    width: 100%;
    height: 5.5em;
    background-color: var(--nav-bg-color);
    padding: 0 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;

    .logo-hl-container {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;

      h1 {
        color: var(--white-color);
        font-size: 1.5em;
      }

      img {
        margin-right: 1em;
        width: 12em;
      }
    }
  }

  .logout-container {
    font-family: var(--font-family);
    font-size: 1em;
    display: flex;
    justify-content: end;
    margin-top: 0.5em;

    i {
      margin-right: 0.3em;
    }
  }

  .button-size {
    height: 4em;
    width: 4em;
    border-radius: 5px;
    padding: 0;
  }

  .profile-picture {
    height: 4em;
    width: 4em;
    border-radius: 5px;
  }

  .profile-picture-empty {
    height: 4em;
    width: 4em;
    border-radius: 5px;
    background-color: var(--profile-bg-color);
    border: solid 2px var(--rsrg-orange);
    color: var(--rsrg-orange);
    font-weight: 600;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
  }

  .overlay-container {
    display: flex;
    align-items: center;
    gap: 1em;
    min-width: 20em;

    div:first-child {
      flex-grow: 1;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    color: #000;
    font-weight: 700;
    font-size: 1.3em;
    gap: 0.3em;

    .job-name-container {
      font-size: 0.7em;
      font-weight: 500;
      color: var(--rsrg-grey);
    }
  }

  .logout-button {
    font-family: var(--font-family);
    font-size: 1em;
    display: flex;
    justify-content: end;
    margin-top: 0.5em;

    i {
      margin-right: 0.3em;
    }
  }

  .avatar-design {
    height: 4em;
    width: 4em;
    border: var(--rsrg-grey) solid 1px;
  }

  .avatar-profile {
    height: 3em;
    width: 3em;
    border-radius: 10px;
    background-color: var(--profile-bg-color);
    border: solid 2px var(--rsrg-orange);
    color: var(--rsrg-orange);
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
  }

  @media only screen and (max-width: 600px) {
    .navbar-container {
      height: 7em;
    }

    .logo-hl-container {
      display: flex;
      flex-wrap: wrap;

      h1 {
        margin: 0;
      }
    }
  }
</style>
