/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FuePrognoseGruppeZuZeitpunkt } from './FuePrognoseGruppeZuZeitpunkt';
import {
    instanceOfFuePrognoseGruppeZuZeitpunkt,
    FuePrognoseGruppeZuZeitpunktFromJSON,
    FuePrognoseGruppeZuZeitpunktFromJSONTyped,
    FuePrognoseGruppeZuZeitpunktToJSON,
} from './FuePrognoseGruppeZuZeitpunkt';
import type { FuerUmsatz } from './FuerUmsatz';
import {
    instanceOfFuerUmsatz,
    FuerUmsatzFromJSON,
    FuerUmsatzFromJSONTyped,
    FuerUmsatzToJSON,
} from './FuerUmsatz';

/**
 * @type RechenmodellRequest
 * 
 * @export
 */
export type RechenmodellRequest = FuePrognoseGruppeZuZeitpunkt | FuerUmsatz;

export function RechenmodellRequestFromJSON(json: any): RechenmodellRequest {
    return RechenmodellRequestFromJSONTyped(json, false);
}

export function RechenmodellRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechenmodellRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...FuePrognoseGruppeZuZeitpunktFromJSONTyped(json, true), ...FuerUmsatzFromJSONTyped(json, true) };
}

export function RechenmodellRequestToJSON(value?: RechenmodellRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }

    if (instanceOfFuePrognoseGruppeZuZeitpunkt(value)) {
        return FuePrognoseGruppeZuZeitpunktToJSON(value as FuePrognoseGruppeZuZeitpunkt);
    }
    if (instanceOfFuerUmsatz(value)) {
        return FuerUmsatzToJSON(value as FuerUmsatz);
    }

    return {};
}

