/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PrognoseGruppeTyp } from './PrognoseGruppeTyp';
import {
    PrognoseGruppeTypFromJSON,
    PrognoseGruppeTypFromJSONTyped,
    PrognoseGruppeTypToJSON,
} from './PrognoseGruppeTyp';
import type { UmsatzBerechnungsParameter } from './UmsatzBerechnungsParameter';
import {
    UmsatzBerechnungsParameterFromJSON,
    UmsatzBerechnungsParameterFromJSONTyped,
    UmsatzBerechnungsParameterToJSON,
} from './UmsatzBerechnungsParameter';

/**
 * 
 * @export
 * @interface KalkulatorischerAnsatz
 */
export interface KalkulatorischerAnsatz {
    /**
     * 
     * @type {number}
     * @memberof KalkulatorischerAnsatz
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof KalkulatorischerAnsatz
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof KalkulatorischerAnsatz
     */
    bis: string;
    /**
     * 
     * @type {PrognoseGruppeTyp}
     * @memberof KalkulatorischerAnsatz
     */
    prognoseGruppeTyp: PrognoseGruppeTyp;
    /**
     * 
     * @type {string}
     * @memberof KalkulatorischerAnsatz
     */
    maschinenGruppe: string;
    /**
     * 
     * @type {UmsatzBerechnungsParameter}
     * @memberof KalkulatorischerAnsatz
     */
    berechnungsParameter: UmsatzBerechnungsParameter;
}

/**
 * Check if a given object implements the KalkulatorischerAnsatz interface.
 */
export function instanceOfKalkulatorischerAnsatz(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "von" in value;
    isInstance = isInstance && "bis" in value;
    isInstance = isInstance && "prognoseGruppeTyp" in value;
    isInstance = isInstance && "maschinenGruppe" in value;
    isInstance = isInstance && "berechnungsParameter" in value;

    return isInstance;
}

export function KalkulatorischerAnsatzFromJSON(json: any): KalkulatorischerAnsatz {
    return KalkulatorischerAnsatzFromJSONTyped(json, false);
}

export function KalkulatorischerAnsatzFromJSONTyped(json: any, ignoreDiscriminator: boolean): KalkulatorischerAnsatz {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'von': json['von'],
        'bis': json['bis'],
        'prognoseGruppeTyp': PrognoseGruppeTypFromJSON(json['prognoseGruppeTyp']),
        'maschinenGruppe': json['maschinenGruppe'],
        'berechnungsParameter': UmsatzBerechnungsParameterFromJSON(json['berechnungsParameter']),
    };
}

export function KalkulatorischerAnsatzToJSON(value?: KalkulatorischerAnsatz | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'von': value.von,
        'bis': value.bis,
        'prognoseGruppeTyp': PrognoseGruppeTypToJSON(value.prognoseGruppeTyp),
        'maschinenGruppe': value.maschinenGruppe,
        'berechnungsParameter': UmsatzBerechnungsParameterToJSON(value.berechnungsParameter),
    };
}

