/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const BerechnungsParameterWertQuelle = {
    Umsatz: 'UMSATZ',
    KalkulatorischerAnsatz: 'KALKULATORISCHER_ANSATZ'
} as const;
export type BerechnungsParameterWertQuelle = typeof BerechnungsParameterWertQuelle[keyof typeof BerechnungsParameterWertQuelle];


export function BerechnungsParameterWertQuelleFromJSON(json: any): BerechnungsParameterWertQuelle {
    return BerechnungsParameterWertQuelleFromJSONTyped(json, false);
}

export function BerechnungsParameterWertQuelleFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerechnungsParameterWertQuelle {
    return json as BerechnungsParameterWertQuelle;
}

export function BerechnungsParameterWertQuelleToJSON(value?: BerechnungsParameterWertQuelle | null): any {
    return value as any;
}

