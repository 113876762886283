/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BerechnungsKnoten } from './BerechnungsKnoten';
import {
    BerechnungsKnotenFromJSON,
    BerechnungsKnotenFromJSONTyped,
    BerechnungsKnotenToJSON,
} from './BerechnungsKnoten';
import type { PrognoseGruppeTyp } from './PrognoseGruppeTyp';
import {
    PrognoseGruppeTypFromJSON,
    PrognoseGruppeTypFromJSONTyped,
    PrognoseGruppeTypToJSON,
} from './PrognoseGruppeTyp';

/**
 * 
 * @export
 * @interface BerechnungsBaum
 */
export interface BerechnungsBaum {
    /**
     * 
     * @type {number}
     * @memberof BerechnungsBaum
     */
    id: number;
    /**
     * 
     * @type {PrognoseGruppeTyp}
     * @memberof BerechnungsBaum
     */
    prognoseGruppeTyp: PrognoseGruppeTyp;
    /**
     * 
     * @type {string}
     * @memberof BerechnungsBaum
     */
    von: string;
    /**
     * 
     * @type {string}
     * @memberof BerechnungsBaum
     */
    bis: string;
    /**
     * 
     * @type {BerechnungsKnoten}
     * @memberof BerechnungsBaum
     */
    knoten?: BerechnungsKnoten;
}

/**
 * Check if a given object implements the BerechnungsBaum interface.
 */
export function instanceOfBerechnungsBaum(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "prognoseGruppeTyp" in value;
    isInstance = isInstance && "von" in value;
    isInstance = isInstance && "bis" in value;

    return isInstance;
}

export function BerechnungsBaumFromJSON(json: any): BerechnungsBaum {
    return BerechnungsBaumFromJSONTyped(json, false);
}

export function BerechnungsBaumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BerechnungsBaum {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'prognoseGruppeTyp': PrognoseGruppeTypFromJSON(json['prognoseGruppeTyp']),
        'von': json['von'],
        'bis': json['bis'],
        'knoten': !exists(json, 'knoten') ? undefined : BerechnungsKnotenFromJSON(json['knoten']),
    };
}

export function BerechnungsBaumToJSON(value?: BerechnungsBaum | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'prognoseGruppeTyp': PrognoseGruppeTypToJSON(value.prognoseGruppeTyp),
        'von': value.von,
        'bis': value.bis,
        'knoten': BerechnungsKnotenToJSON(value.knoten),
    };
}

