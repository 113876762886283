/* tslint:disable */
/* eslint-disable */
/**
 * Umsatzcontrolling
 * Money
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BerechnungsBaum,
  BerechnungsParameter,
  Einheit,
  Firma,
  MaschinenGruppe,
  ParameterTag,
  PrognoseGruppe,
  RechenmodellRequest,
} from '../models/index';
import {
    BerechnungsBaumFromJSON,
    BerechnungsBaumToJSON,
    BerechnungsParameterFromJSON,
    BerechnungsParameterToJSON,
    EinheitFromJSON,
    EinheitToJSON,
    FirmaFromJSON,
    FirmaToJSON,
    MaschinenGruppeFromJSON,
    MaschinenGruppeToJSON,
    ParameterTagFromJSON,
    ParameterTagToJSON,
    PrognoseGruppeFromJSON,
    PrognoseGruppeToJSON,
    RechenmodellRequestFromJSON,
    RechenmodellRequestToJSON,
} from '../models/index';

export interface PrognoseGruppenRequest {
    filter: string;
}

export interface RechenmodellOperationRequest {
    rechenmodellRequest?: RechenmodellRequest;
}

/**
 * AllgemeinApi - interface
 * 
 * @export
 * @interface AllgemeinApiInterface
 */
export interface AllgemeinApiInterface {
    /**
     * 
     * @summary Gibt eine Liste aller Berechnungsparameter zurück.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllgemeinApiInterface
     */
    berechnungsParameterRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BerechnungsParameter>>>;

    /**
     * Gibt eine Liste aller Berechnungsparameter zurück.
     */
    berechnungsParameter(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BerechnungsParameter>>;

    /**
     * 
     * @summary Gibt eine Liste mit Einheiten zurück.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllgemeinApiInterface
     */
    einheitenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Einheit>>>;

    /**
     * Gibt eine Liste mit Einheiten zurück.
     */
    einheiten(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Einheit>>;

    /**
     * 
     * @summary Gibt eine Liste mit Firmen zurück.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllgemeinApiInterface
     */
    firmenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Firma>>>;

    /**
     * Gibt eine Liste mit Firmen zurück.
     */
    firmen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Firma>>;

    /**
     * 
     * @summary Gibt eine Liste mit Maschinengruppen zurück.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllgemeinApiInterface
     */
    maschinenGruppenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MaschinenGruppe>>>;

    /**
     * Gibt eine Liste mit Maschinengruppen zurück.
     */
    maschinenGruppen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MaschinenGruppe>>;

    /**
     * 
     * @summary Gibt eine Liste aller Parametertags zurück.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllgemeinApiInterface
     */
    parameterTagsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParameterTag>>>;

    /**
     * Gibt eine Liste aller Parametertags zurück.
     */
    parameterTags(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParameterTag>>;

    /**
     * 
     * @summary Gibt eine Liste von Prognosegruppen zurück.
     * @param {string} filter Gibt den RSQL-Filter für die gewünschten Prognosegruppen an.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllgemeinApiInterface
     */
    prognoseGruppenRaw(requestParameters: PrognoseGruppenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PrognoseGruppe>>>;

    /**
     * Gibt eine Liste von Prognosegruppen zurück.
     */
    prognoseGruppen(requestParameters: PrognoseGruppenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PrognoseGruppe>>;

    /**
     * 
     * @summary Gibt das Berechnungsmodell für einen Umsatz oder für eine Prognosegruppe zu einem Zeitpunkt zurück.
     * @param {RechenmodellRequest} [rechenmodellRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllgemeinApiInterface
     */
    rechenmodellRaw(requestParameters: RechenmodellOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerechnungsBaum>>;

    /**
     * Gibt das Berechnungsmodell für einen Umsatz oder für eine Prognosegruppe zu einem Zeitpunkt zurück.
     */
    rechenmodell(requestParameters: RechenmodellOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerechnungsBaum>;

}

/**
 * 
 */
export class AllgemeinApi extends runtime.BaseAPI implements AllgemeinApiInterface {

    /**
     * Gibt eine Liste aller Berechnungsparameter zurück.
     */
    async berechnungsParameterRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BerechnungsParameter>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/allgemein/berechnungsparameter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BerechnungsParameterFromJSON));
    }

    /**
     * Gibt eine Liste aller Berechnungsparameter zurück.
     */
    async berechnungsParameter(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BerechnungsParameter>> {
        const response = await this.berechnungsParameterRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste mit Einheiten zurück.
     */
    async einheitenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Einheit>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/allgemein/einheiten`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EinheitFromJSON));
    }

    /**
     * Gibt eine Liste mit Einheiten zurück.
     */
    async einheiten(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Einheit>> {
        const response = await this.einheitenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste mit Firmen zurück.
     */
    async firmenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Firma>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/allgemein/firmen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FirmaFromJSON));
    }

    /**
     * Gibt eine Liste mit Firmen zurück.
     */
    async firmen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Firma>> {
        const response = await this.firmenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste mit Maschinengruppen zurück.
     */
    async maschinenGruppenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MaschinenGruppe>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/allgemein/maschinengruppen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MaschinenGruppeFromJSON));
    }

    /**
     * Gibt eine Liste mit Maschinengruppen zurück.
     */
    async maschinenGruppen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MaschinenGruppe>> {
        const response = await this.maschinenGruppenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste aller Parametertags zurück.
     */
    async parameterTagsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParameterTag>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/allgemein/parametertags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParameterTagFromJSON));
    }

    /**
     * Gibt eine Liste aller Parametertags zurück.
     */
    async parameterTags(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParameterTag>> {
        const response = await this.parameterTagsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste von Prognosegruppen zurück.
     */
    async prognoseGruppenRaw(requestParameters: PrognoseGruppenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PrognoseGruppe>>> {
        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling prognoseGruppen.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/allgemein/prognosegruppen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PrognoseGruppeFromJSON));
    }

    /**
     * Gibt eine Liste von Prognosegruppen zurück.
     */
    async prognoseGruppen(requestParameters: PrognoseGruppenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PrognoseGruppe>> {
        const response = await this.prognoseGruppenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt das Berechnungsmodell für einen Umsatz oder für eine Prognosegruppe zu einem Zeitpunkt zurück.
     */
    async rechenmodellRaw(requestParameters: RechenmodellOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BerechnungsBaum>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/allgemein/rechenmodell`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RechenmodellRequestToJSON(requestParameters.rechenmodellRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BerechnungsBaumFromJSON(jsonValue));
    }

    /**
     * Gibt das Berechnungsmodell für einen Umsatz oder für eine Prognosegruppe zu einem Zeitpunkt zurück.
     */
    async rechenmodell(requestParameters: RechenmodellOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BerechnungsBaum> {
        const response = await this.rechenmodellRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
